<template>
  <div>
    <search-agent-sim-card
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-dropdown placement="bottomCenter" v-if="batchOperationOptions.length > 0">
          <a-menu
            slot="overlay"
            @click="handleBatchOperation"
          >
            <a-menu-item
              v-for="option in batchOperationOptions"
              :key="option.slug"
            >
              {{ option.name }}
            </a-menu-item>
          </a-menu>
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
        <a-button type="primary" @click="exportData">导出卡明细</a-button>
        <a-button type="primary" @click="showFlowModal">导出流量详情</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="iccid"
    >
      <div slot="iccid" slot-scope="iccid, record">
        <a @click="showInfoModal(record.id)">{{ iccid }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-sim-card
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <!-- 流量详情导出模态框 -->
    <sim-card-flow
      v-if="isShowFlowModal"
      :visible.sync="isShowFlowModal"
      :agent-id="agentId"
      @completed="exportFlowData"
    />

    <!-- 更新卡信息模态框 -->
    <update-sim-cards-info
      v-if="isShowUpdateInfoModal"
      :visible.sync="isShowUpdateInfoModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- 提请销卡模态框 -->
    <request-cancel-card
      v-if="isShowRequestCancelCardModal"
      :visible.sync="isShowRequestCancelCardModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- 强制断网模态框 -->
    <force-net-down-card
      v-if="isShowForceNetDownCardModal"
      :visible.sync="isShowForceNetDownCardModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- 取消强制断网模态框 -->
    <cancel-force-net-down-card
      v-if="isShowCancelForceNetDownCardModal"
      :visible.sync="isShowCancelForceNetDownCardModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- 设置测试流量模态框 -->
    <set-test-traffic
      v-if="isShowSetTestTrafficModal"
      :visible.sync="isShowSetTestTrafficModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- 上报语音白名单模态框 -->
    <upload-white-list
      v-if="isShowUploadWhiteListModal"
      :visible.sync="isShowUploadWhiteListModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- 融合卡强制激活模态框 -->
    <serv-active
      v-if="isShowServActiveModal"
      :visible.sync="isShowServActiveModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />

    <!-- vpdn分组配置 -->
    <update-vpdn
      v-if="isShowUpdateVpdnModal"
      :visible.sync="isShowUpdateVpdnModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findAgentSimCards, exportAgentSimCards, exportAgentSimCardsFlow } from '@/api/sim_card'
import Pagination from '@/components/Pagination'
import SearchAgentSimCard from '@/views/sim_cards/agent/Search'
import { findSimCardBatchOperationOptions } from '@/api/batch_operation'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentSimCardList',
  components: {
    SearchAgentSimCard,
    Pagination,
    ShowSimCard: () => import('@/views/sim_cards/Show'),
    SimCardFlow: () => import('@/views/sim_cards/Flow'),
    UpdateSimCardsInfo: () => import('@/views/batch_operations/UpdateAgentSimCardsInfo'),
    RequestCancelCard: () => import('@/views/batch_operations/RequestCancelCard'),
    UpdateVpdn: () => import('@/views/batch_operations/UpdateVpdn'),
    ForceNetDownCard: () => import('@/views/batch_operations/ForceNetDownAgentSimCards'),
    CancelForceNetDownCard: () => import('@/views/batch_operations/CancelForceNetDownAgentSimCards'),
    UploadWhiteList: () => import('@/views/batch_operations/UploadWhiteListAgentSimCards'),
    ServActive: () => import('@/views/batch_operations/ServActiveAgentSimCards'),
    SetTestTraffic: () => import('@/views/batch_operations/SetTestTrafficAgentSimCards')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowUpdateVpdnModal: false, // 是否显示vpdn分组配置弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowFlowModal: false, // 是否显示流量详情导出弹框
      isShowUpdateInfoModal: false, // 是否显示更新卡信息弹窗
      isShowRequestCancelCardModal: false, // 是否显示提请销卡弹窗
      isShowForceNetDownCardModal: false, // 是否显示强制断网模态框
      isShowCancelForceNetDownCardModal: false, // 是否显示取消强制断网模态框
      isShowSetTestTrafficModal: false, // 是否显示设置测试流量模态框
      isShowUploadWhiteListModal: false, // 是否显示语音白名单上报模态框
      isShowServActiveModal: false, // 是否显示融合卡强制激活模态框
      showingId: 0, // 正在查看详情的记录id
      selectedRowKeys: [],
      batchOperationOptions: [], // 批量操作选项
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.fetchAgentSimCardBatchOperationOptions()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: 'IMSI',
          dataIndex: 'imsi'
        },
        {
          title: '短号',
          dataIndex: 'short_number'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        },
        {
          title: '运营商套餐',
          dataIndex: 'carrier_product_name'
        },
        {
          title: 'VPDN分组ID',
          dataIndex: 'vpdn_id'
        },
        {
          title: '运营商状态',
          dataIndex: 'card_status'
        },
        {
          title: '生命周期',
          dataIndex: 'life_cycle'
        },
        {
          title: '联网状态',
          dataIndex: 'net_status'
        },
        {
          title: '划拨对象',
          dataIndex: 'agent_name'
        },
        {
          title: '是否要实名认证',
          dataIndex: 'is_need_verified'
        },
        {
          title: '实名认证状态',
          dataIndex: 'authentication_status'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '客户套餐',
          dataIndex: 'product_name'
        },
        {
          title: '服务周期起始时间(激活时间)',
          dataIndex: 'activated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'activated_at' ? this.sort.sort_order : false
        },
        {
          title: '服务周期结束时间',
          dataIndex: 'service_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'service_end_time' ? this.sort.sort_order : false
        },
        {
          title: '套餐服务起始时间',
          dataIndex: 'product_effective_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'product_effective_time' ? this.sort.sort_order : false
        },
        {
          title: '套餐服务结束时间',
          dataIndex: 'product_expiration_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'product_expiration_time' ? this.sort.sort_order : false
        },
        {
          title: '后续套餐个数',
          dataIndex: 'future_package_count'
        },
        {
          title: '周期开始时间',
          dataIndex: 'cycle_begin_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_begin_time' ? this.sort.sort_order : false
        },
        {
          title: '周期结束时间',
          dataIndex: 'cycle_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_end_time' ? this.sort.sort_order : false
        },
        {
          title: '周期容量',
          dataIndex: 'cycle_total',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_total' ? this.sort.sort_order : false
        },
        {
          title: '周期已用量',
          dataIndex: 'cycle_usage',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_usage' ? this.sort.sort_order : false
        },
        {
          title: '周期剩余量',
          dataIndex: 'cycle_left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_left' ? this.sort.sort_order : false
        },
        {
          title: '语音容量(分钟)',
          dataIndex: 'voice_total',
          sorter: true,
          sortOrder: this.sort.sort_field === 'voice_total' ? this.sort.sort_order : false
        },
        {
          title: '语音用量(分钟)',
          dataIndex: 'voice_usage',
          sorter: true,
          sortOrder: this.sort.sort_field === 'voice_usage' ? this.sort.sort_order : false
        },
        {
          title: '语音剩余量(分钟)',
          dataIndex: 'voice_left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'voice_left' ? this.sort.sort_order : false
        },
        {
          title: '后续周期个数',
          dataIndex: 'future_cycle_count'
        },
        {
          title: '订单编号',
          dataIndex: 'order_no'
        },
        {
          title: '数据更新时间',
          dataIndex: 'data_updated_at'
        }
      ]
    },

    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleBatchOperation(e) {
      switch (e.key) {
        case 'update_batch' :
          this.isShowUpdateInfoModal = true
          break
        case 'update_vpdn' :
          this.isShowUpdateVpdnModal = true
          break
        case 'request_cancel_card' :
          this.isShowRequestCancelCardModal = true
          break
        case 'force_net_down' :
          this.isShowForceNetDownCardModal = true
          break
        case 'cancel_force_net_down' :
          this.isShowCancelForceNetDownCardModal = true
          break
        case 'set_test_traffic' :
          this.isShowSetTestTrafficModal = true
          break
        case 'upload_white_list' :
          this.isShowUploadWhiteListModal = true
          break
        case 'serv_active' :
          this.isShowServActiveModal = true
          break
      }
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    showFlowModal(id) {
      this.isShowFlowModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchAgentSimCardBatchOperationOptions() {
      findSimCardBatchOperationOptions().then((res) => {
        if (res.code === 0) {
          this.batchOperationOptions = res.data
        }
      })
    },
    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findAgentSimCards(Object.assign({ agent_id: this.agentId },
        this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },
    exportData() {
      exportAgentSimCards(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    },
    exportFlowData(args) {
      exportAgentSimCardsFlow(Object.assign({ agent_id: this.agentId, start_month: args.startMonth, end_month: args.endMonth }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
